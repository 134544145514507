import React from 'react';
import ToggleSwitch from 'js/components/common/toggleSwitch';
import envStore from 'js/envStore';
import {HELP_ARTICLE_ANON_SUBMISSIONS_URL} from 'js/constants';
import Icon from 'js/components/common/icon';
import styles from './anonymousSubmission.module.scss';

interface AnonymousSubmissionProps {
  checked: boolean;
  onChange: (isChecked: boolean) => void;
}

export default function AnonymousSubmission(props: AnonymousSubmissionProps) {
  return (
    <div className={styles.root}>
      <ToggleSwitch
        checked={props.checked}
        onChange={props.onChange}
        label={t(
          'Allow submissions to this form without a username and password'
        )}
      />
    </div>
  );
}
